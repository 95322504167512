import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Flex } from 'rebass'
import logo from './symbol.svg'

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Logo = styled.img`
  animation: ${rotate} 2s ease-in-out infinite;
`

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loading = ({ children = 'Laster inn...' }) => (
  <Container data-testid='loading'>
    <Flex flexDirection='column' alignItems='center'>
      <Logo src={logo} alt='Arkivet logo' />
      {/* <Text mt={3} fontWeight='semibold'>{children}</Text> */}
    </Flex>
  </Container>
)

export default Loading

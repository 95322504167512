import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

// rrf = react-redux-firebase
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
  // useFirestoreForStorageMeta: true, // Store in Firestore instead of Real Time DB
  enableLogging: false,
  logErrors: true
}

const firestore = firebase.firestore()
// const settings = { timestampsInSnapshots: true }
// firestore.settings(settings)
// firestore.enablePersistence({ experimentalTabSynchronization: true })

export { firebase, firestore, rrfConfig }

export const HOME = '/'
export const SIGN_UP = '/signup'
export const LOG_IN = '/login'
export const LOG_OUT = '/logout'
export const FORGOT_PASSWORD = '/forgot-password'
export const SETTINGS = '/settings'
export const PAYMENTS = '/payments'
export const CHECKOUT = '/checkout'
export const PROJECTS = '/projects'
export const DESIGNERS = '/designers'
export const SHOWROOM = '/page/showroom'
export const BUSINESS = '/page/bedrift'
export const HOMES = '/page/bolig'
export const OUTDOORS = '/page/uterom'
export const ABOUT = '/page/om-arkivet'
export const USERS = '/users'
export const ADMIN = '/admin'

import { Router } from '@reach/router'
import React, { Component, Fragment, lazy } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Error from './components/Error'
import Footer from './components/Footer'
// Constants
import * as routes from './constants/routes'
// Code splitted views
import { AuthProvider } from './AuthContext'
import NotFound from './views/404'

const MainMenu = lazy(() => import('./components/MainMenu'))
const Home = lazy(() => import('./views/home'))
const Designers = lazy(() => import('./views/designers'))
const Page = lazy(() => import('./views/pages'))
const Signup = lazy(() => import('./views/signup'))
const Login = lazy(() => import('./views/login'))
const Logout = lazy(() => import('./views/logout'))
const ForgotPassword = lazy(() => import('./views/forgotPassword'))
const User = lazy(() => import('./views/user'))
const Projects = lazy(() => import('./views/projects'))
const Project = lazy(() => import('./views/project'))
const NewProject = lazy(() => import('./views/projects/new'))
const Settings = lazy(() => import('./views/settings'))
const Checkout = lazy(() => import('./views/checkout'))
const CheckoutSuccess = lazy(() => import('./views/checkout/success'))
const PaymentReceipt = lazy(() => import('./views/payment/receipt'))
const PaymentSalesReceipt = lazy(() => import('./views/payment/salesReceipt'))
const Admin = lazy(() => import('./views/admin'))

let Sentry
if (process.env.NODE_ENV === 'production') {
  Sentry = require('@sentry/browser')
  Sentry.init({
    dsn: 'https://3e2010953ad04e939ca3f0a79228dd1b@sentry.io/1232666',
  })
}

class App extends Component {
  state = { hasError: false }
  componentDidCatch(error, info) {
    console.log('componentDidCatch', { error }, { info })
    this.setState({ hasError: true, errors: error })
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        Object.keys(info).forEach((key) => {
          scope.setExtra(key, info[key])
        })
        Sentry.captureException(error)
      })
    }
  }

  render() {
    const { errors } = this.props
    return errors.allIds.length ? (
      <Error>
        {errors.allIds.map(
          (error) =>
            `En feil har oppstått: ${errors.byQuery[error].message} [${errors.byQuery[error].code}]`
        )}
      </Error>
    ) : this.state.hasError ? (
      <Error>
        En ukjent feil har oppstått. Forsøk å laste inn siden på nytt.{' '}
        {JSON.stringify(this.state.errors)}
      </Error>
    ) : (
      <Fragment>
        <Helmet
          defaultTitle="Markedsplassen for interiørdesign - Arkivet"
          titleTemplate="%s - Arkivet"
        >
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
          ></link>
        </Helmet>
        <AuthProvider>
          <Router>
            <MainMenu default>
              <NotFound default />
              <NotFound path="/404" />
              <Home path={routes.HOME} />
              <Designers path={routes.DESIGNERS} />
              <Page path={'/page/*'} />
              <Signup path={routes.SIGN_UP} />
              <Login path={routes.LOG_IN} />
              <Logout path={routes.LOG_OUT} />
              <ForgotPassword path={routes.FORGOT_PASSWORD} />
              <User path={`${routes.USERS}/:slug`} />
              <Projects path={routes.PROJECTS} />
              <Project path={`${routes.PROJECTS}/:id`} />
              <NewProject path={`${routes.PROJECTS}/new/:designerId/*`} />
              <Settings path={`${routes.SETTINGS}/*`} />
              <Checkout path={`${routes.CHECKOUT}/:paymentId`} />
              <CheckoutSuccess path={`${routes.CHECKOUT}/:paymentId/success/:projectId`} />
              <PaymentReceipt path={`${routes.PAYMENTS}/:paymentId/receipt`} />
              <PaymentSalesReceipt path={`${routes.PAYMENTS}/:paymentId/sales-receipt`} />
            </MainMenu>
            <Admin path={`${routes.ADMIN}/*`} />
          </Router>
          {/* <CookieBar /> */}
          <Footer />
        </AuthProvider>
      </Fragment>
    )
  }
}
export { App }
export default compose(
  connect(({ firebase, firestore }) => ({
    auth: firebase.auth,
    errors: firestore.errors,
  }))
)(App)
